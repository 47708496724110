import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import LoginController from "../services/api/http.service";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { hideMessage, showAuthLoader, userSignIn } from "actions/Auth";

const SignIn = props => {
  const [email, setEmail] = useState("");
  //const [email, setEmail] = useState("prueba@auditor.app");
  const [password, setPassword] = useState("");
  //const [password, setPassword] = useState("123456");
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  const crypto = require("crypto-js");
  const baseUrl = "http://localhost:5000";

  //let requiredFieldsError = false;

  // const submitForm = () => {
  //   if (email === "" || password === "") {
  //     setError("Campos requeridos");
  //     return;
  //   }
  //   props.login({ email, password });
  // };

  // function Alert(props) {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }

  // const authentication = async key => {
  //   const params = {
  //     key: key
  //   };
  //   const options = {
  //     method: "POST",
  //     body: JSON.stringify(params),
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   };
  //   console.log(options);
  //   try {
  //     let data = await fetch(baseUrl + "/authentication/CRM", options);
  //     //console.log("data tokenData", data);
  //     data = await data.json();
  //     //console.log("datajson tokenData", data);
  //     if (data.success) {
  //       return data.token;
  //     }
  //   } catch (err) {
  //     console.log("error tokenData", err);
  //     throw err;
  //   }
  // };

  // const loginData = async (email, password, access_token) => {
  //   try {
  //     console.log("loginData", email, password, access_token);
  //     let dataLogin = {
  //       email: email,
  //       password: password,
  //       access_token: access_token
  //     };
  //     console.log("dataLogin******", dataLogin);
  //     let stringData = JSON.stringify(dataLogin);
  //     var textToEncrypt = stringData;

  //     //get key
  //     let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
  //     var password = key;

  //     const encrypt = function(text) {
  //       return crypto.AES.encrypt(text, password).toString();
  //     };

  //     var cipherText = encrypt(textToEncrypt);
  //     //var encryptingData = await this.encryptData(dataLogin);
  //     console.log("cipherText", cipherText);

  //     const params = {
  //       data: cipherText
  //     };
  //     const options = {
  //       method: "POST",
  //       body: JSON.stringify(params),
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     };

  //     let data = await fetch(baseUrl + "/login/CRM", options);
  //     console.log("data loginData", data);
  //     data = await data.json();
  //     console.log("datajson loginData", data);
  //   } catch (err) {
  //     console.log("error loginData", err);
  //     throw err;
  //   }
  // };
  // const authenticationUser = () => {
  //   authentication("uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5hUJ6TD")
  //     .then(res => {
  //       console.log("res tokenData", res);
  //       setToken(res);
  //       console.log("token", token);
  //       console.log("email", email);
  //       console.log("password", password);
  //       loginData(email, password, token)
  //         .then(res => {
  //           console.log("token after", token);
  //           console.log("email after", email);
  //           console.log("password after", password);
  //           console.log("res loginData", res);
  //         })
  //         .catch(error => {
  //           console.log("error catch loginData", error);
  //         });
  //     })
  //     .catch(error => {
  //       console.log("error catch tokenData", error);
  //     });
  // };

  // useEffect(async () => {
  //   authenticationUser();
  // }, []);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [showMessage, authUser, props.history, dispatch]);

  // };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img
              styles="object-fit: contain"
              src={require("assets/images/logo.png")}
              alt="Auditor"
              title="Auditor"
            />
          </Link>
        </div>
        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>Ingresa al Sistema</h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={event => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label="Contraseña"
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Link 
                    to="/login/resetpassword"
                    title="Reset Password">
                      Recuperar Contraseña
                  </Link>
                  <Button
                    onClick={() => {
                      //submitForm();
                      //authenticationUser()
                      dispatch(showAuthLoader());
                      dispatch(userSignIn({ email, password }));
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Iniciar Sesión
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      {showMessage && NotificationManager.error(alertMessage)}
      <NotificationContainer />
    </div>
  );
};
export default SignIn;


//===================================================================================================== smart abajo
/*

import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from 'react-redux';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from 'actions/Auth';


class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: 'prueba@auditor.app',
      password: '12345'
    }
  }

  

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    // if (this.props.authUser !== null) {
    //   this.props.history.push('/');
    // }
  }

  handleLogin = (values) => this.props.onLogin ? this.props.onLogin(values) : null 

  render() {
    const {
      email,
      password
    } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img src={require("assets/images/logo.png")} alt="Smart Solutions" title="Smart Solutions" className="w-100" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Iniciar Sesión</h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) => this.setState({ email: event.target.value })}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) => this.setState({ password: event.target.value })}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => this.handleLogin({ email, password })}
                      variant="contained"
                      color="primary">
                      Iniciar Sesión
                    </Button>
                  </div>

          

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress />
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(SignIn);
*/
