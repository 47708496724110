import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVert from "@material-ui/icons/MoreVert";
import Options from "./options";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
//import 'app/routes/Privilegios/routes/List/node_modules/moment/locale/es'
import { Button } from "@material-ui/core";

const styles = {
  smallColumn: {
    maxWidth: "5%",
  },
};

class ClientTableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: undefined,
      menuState: false,
    };
  }

  onOptionMenuSelect = (event) => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ menuState: false });
  };

  render() {
    const { isItemSelected, labelId, row, handleClick } = this.props,
      { menuState, anchorEl } = this.state;

    Moment.locale("es");
    //('MMMM Do YYYY, h:mm:ss a')
    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
      >
        <TableCell align="left">
          {row.name + " " + row.last_name + " " + row.second_name ||
            "No registrado"}
        </TableCell>
        <TableCell align="left">$ {row.amount || "No registrado"}</TableCell>
        <TableCell align="left">
          {Moment(row.createdAt).format("l LT") || "No registrado"}
        </TableCell>
        <TableCell align="left">
          {Moment(row.approval_date).format("l LT") || "No registrado"}
        </TableCell>
        <TableCell align="left">{row.estatus || "No registrado"}</TableCell>
        <TableCell align="rigth">
          {
            <div
              style={{
                backgroundColor: row.statusColor,
                width: 30,
                height: 30,
                borderRadius: "50%",
                display: "inline-block",
                marginLeft: 10,
              }}
            />
          }
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={this.onOptionMenuSelect.bind(this)}>
            <MoreVert />
          </IconButton>
          <Options
            menuState={menuState}
            anchorEl={anchorEl}
            handleRequestClose={this.handleRequestClose.bind(this)}
            id={row.payment_orderId}
            reloadData={this.props.reloadData}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export default ClientTableCell;
