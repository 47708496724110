import React from 'react'
//import Datatable from '../../../../../components/Datatable'
//import Datatable from '../../../../routes/table/routes/data/Components/DataTable'
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from './rowTable'
import fetchData from '../../../../../services/api/FetchData'
//import Loader from "../../../../../components/Loader"
import Loader from "../../../../../components/CircularProgress"


export default class List extends React.Component {

  state = {
    data: [],
    headers: [
      { id: 'auditorId', numeric: false, disablePadding: false, label: 'Auditor' },
      { id: 'amount', numeric: false, disablePadding: false, label: 'Cantidad' },
      { id: 'createdAt', numeric: false, disablePadding: false, label: 'Fecha de Solicitud' },
      { id: 'approval_date', numeric: false, disablePadding: false, label: 'Fecha de depósito' },
      { id: 'statusId', numeric: false, disablePadding: false, label: 'Estatus' },
      { id: "statusColor", numeric: true, disablePadding: false, label: " "},
      { id: 'options', numeric: true, disablePadding: false, label: 'Transferencia' },
    ]
  }

  setData = async (route, dataName) => {
    const params = {
      method: 'GET',
    }
    try {
      const response = await fetchData(route, params)
      let paymentOrdersRespond = response.data;
      if (response.success) {
        //////////////////////////////
        //console.log("************** 10", response.data)
        const crypto = require("crypto-js"); 
        let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
        var password = key; 

        //decipher data
        const decrypt = function (ciphertext) {
            var bytes  = crypto.AES.decrypt(ciphertext, password);
            return bytes.toString(crypto.enc.Utf8);
        }
 
        //parse data
        var uncipherText = decrypt(paymentOrdersRespond);
        let text = JSON.parse(uncipherText);
        let data = text;
///////////////////////////////////////////////////////////////////////
        
        for (let i = 0; i < text.length; i++) {
        
          let estatus =  text[i].estatus;
          if (estatus == "Pendiente") {
            text[i].statusColor = "#ffff00";
          } else if (estatus == "Aprobado") {
            text[i].statusColor = "#70ad47";
          } else if (estatus == "Rechazado") {
            text[i].statusColor = "#d9534f";
          } else if (estatus == "Pagado") {
            text[i].statusColor = "#4472c4";
          }
          else{
            text[i].statusColor = "#717883"
          }
        }
        //console.log("****************************************  1\n",text);
        this.setState({
          ...this.state,
          [dataName]: data
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

   componentDidMount() {
    this.setData(`payment_orders/auditors`, "data")
  }


  reloadData = () => this.props.history.push("/app/dashboard/crm")

  render() {
    return (
      this.state.data.length != 0 ?
      <div className="container">
        <div className="row">
          <div className="col-md-12  mr-2">
            <Datatable RowComponent={RowTable} data={this.state.data} headers={this.state.headers} reloadData={this.reloadData} title="Transferencias"/>
          </div>
        </div>
        </div>        
        :
        <Loader/>

    )
  }

}

