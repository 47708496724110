import React, { Component, useState } from "react";
import { Link } from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import swal from 'sweetalert';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import fetchData from "../../../../services/api/FetchData"
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const requiredField = "Campo Requerido"
let todayDate = new Date()

class DataDisplayer extends Component {

  constructor(props) {
    super(props)
    if (props.payment_order) {
      const { payment_order } = props
      const { estatus } = [1,2,3,4,5]
      const { newStatus } = props
      this.state = { payment_order }
      this.state = { estatus }
      console.log("entro");
      this.state = {
        payment_order : {
          ...payment_order,
        },
        estatus: {
          id: 0,
          name: "",
          status_typeId: 0,
          createdAt:"",
          updatedAt:""   
        },
        banks: {
          id: 0,
          name: "",
          createdAt:"",
          updatedAt:""     
        },
        auditors: {
          id: 0,
          name: "",
          full_name:"",
          second_name:"",
          email:"" 
        }
      }
    }
    else {
      this.state = {
        payment_order: {
          id : 0,
          auditorId : 0,
          amount: 0.0,
          CLABE : "",
          bankId : 0,
          full_name : "",
          statusId : 0,
          userId : 0,
          approval_date : todayDate.toISOString(),
          observations : "",
          operation_folio : "",
          createdAt : "",
          updatedAt : "",
        },
        estatus:{
          id: 0,
          name: "",
          status_typeId: 0,
          createdAt:"",
          updatedAt:""
        },
        banks:{
          id: 0,
          name: "",
          createdAt:"",
          updatedAt:""
        },
        statusList : [],
        banksList : [],
        auditors: {
          id: 0,
          name: "",
          full_name:"",
          second_name:"",
          email:"" 
        }
      } 
    }
  }

  handleSubmit = this.props.onSubmit

  handleCancel = (values) => {
    this.props.onCancel(values)
  }

  handleReject = (values) => {
    this.props.onReject(values)
  }


  handleApprove = (values) => {
    this.props.onApprove(values)
  }

  handlePaid = (values) => {
    console.log("8888888888888888888888888888888888 ", values)
    this.props.onPaid(values)
  }

  searchStatus = async () => {
    const params = {
      method: 'GET',
    }
    const response = await fetchData("status", params)
    console.log("\n\n\n", response);
    if (response.success) {
      const data = response.rows
      this.setState({
        ...this.state,
        estatus: data
      })
      data.map(status => {
        if(status.id == this.state.payment_order.statusId){          
          this.setState({
            ...this.state,
            estatus: status
          })
        }
      })
    }
  }

  searchBanks = async () => {
    const params = {
      method: 'GET',
    }
    const response = await fetchData("banks", params);
    if (response.success) {
      const data = response.rows
      this.setState({
        ...this.state,
        banksList: data
      })
      data.map(bank => {
        if(bank.id == this.state.payment_order.bankId){          
          this.setState({
            ...this.state,
            banks: bank
          })
        }
      })
    }
  }

  searchAuditor = async () => {
    const params = {
      method: 'GET',
    }
    const response = await fetchData("auditors", params)
      let auditorRespond = response.auditors;
        const crypto = require("crypto-js"); 
        let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
        var password = key; 

        //decipher data
        const decrypt = function (ciphertext) {
            var bytes  = crypto.AES.decrypt(ciphertext, password);
            return bytes.toString(crypto.enc.Utf8);
        }
 
        //parse data
        var uncipherText = decrypt(auditorRespond);
        let text = JSON.parse(uncipherText);
        let dataClean = text;
        if (response.success) {
          const data = response.rows
          this.setState({
            ...this.state,
            auditors: dataClean
          })
        
        dataClean.map(auditor => {
        if(auditor.id == this.state.payment_order.auditorId){          
          this.setState({
            ...this.state,
            auditors: auditor
          })
          console.log("\n\n auditors asignado", this.state.auditors)
        }
      })
    }
  }

  componentDidMount(){
    this.searchStatus()
    this.searchBanks()
    this.searchAuditor()
    //this.isAproved()
  }

  /*isAproved(){
    if(values.statusId != 12){
      return true
  }} */

  render() {
    const { payment_order } = this.state
    const { estatus } = this.state
    const isToPay = () => {if(payment_order.statusId != 11){
      return true
    }else{return false}}

    const flag = isToPay()
    console.log("ssssssssssssssssssssssssssss ", flag)
    return (
      <Formik
        initialValues={payment_order}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          id: Yup.number()
            .required(requiredField),
          auditorId: Yup.number()
            .required(requiredField),
          amount: Yup.number()
            .required(requiredField),
          CLABE: Yup.string()
            .required(requiredField),
          bankId: Yup.number()
            .required(requiredField),
          full_name: Yup.string()
            .required(requiredField),
          statusId: Yup.number()
            .required(requiredField),
          userId: Yup.number()
            .required(requiredField),
          approval_date: Yup.date()
            .required(requiredField),
          observations: Yup.string()
            .required(requiredField),
          createdAt : Yup.date()
            .required(requiredField),
        })}
      >        
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            
          } = props   

          return (
            <form className="container-fluid" autoComplete='off' onSubmit={handleSubmit}>
              {/*<div className="row align-items-end mb-4">*/}
                {/*<div className="col-12 mb-2">*/}
                <div className="row">
                  <div className="col">
                    <TextField
                      fullWidth
                      id="outlined-text-input"
                      //label="Nombre"
                      label="Auditor"
                      type="text"
                      //name="name"
                      name="latitude"
                      inputProps={{ readOnly: this.props.readOnly }}
                      onBlur={handleBlur}
                      error={errors.latitude && touched.latitude}
                      helperText={touched.latitude && (errors.latitude)}
                      className={errors.latitude && touched.latitude && "error"}
                      value={this.state.auditors.name + " "  + this.state.auditors.last_name + " " + this.state.auditors.second_name}
                    />
                    </div>
                    <div className="col">
                      <TextField
                        fullWidth
                        id="outlined-text-input"
                        //label="Nombre"
                        label="Nombre completo"
                        type="text"
                        //name="name"
                        name="number_photos"
                        inputProps={{ readOnly: this.props.readOnly }}
                        onBlur={handleBlur}
                        error={errors.number_photos && touched.number_photos}
                        helperText={touched.number_photos && (errors.number_photos)}
                        className={errors.number_photos && touched.number_photos && "error"}
                        value={values.full_name}
                        onChange={handleChange}
                      />                        
                      </div>
                    <div className="col">
                    <TextField
                      fullWidth
                      id="outlined-text-input"
                      //label="Nombre"
                      label="Correo electrónico"
                      type="text"
                      //name="name"
                      name="latitude"
                      inputProps={{ readOnly: this.props.readOnly }}
                      onBlur={handleBlur}
                      error={errors.latitude && touched.latitude}
                      helperText={touched.latitude && (errors.latitude)}
                      className={errors.latitude && touched.latitude && "error"}
                      value={this.state.auditors.email}
                    />
                    </div>
                  </div>
                
                <div className="row">
                  <div className="col">

                        <TextField
                          fullWidth
                          id="outlined-text-input"
                          //label="Nombre"
                          label="Banco"
                          type="text"
                          //name="name"
                          name="amount"
                          inputProps={{ readOnly: this.props.readOnly }}
                          onBlur={handleBlur}
                          error={errors.amount && touched.amount}
                          helperText={touched.amount && (errors.amount)}
                          className={errors.amount && touched.amount && "error"}
                          value={this.state.banks.name}
                          onChange={handleChange}
                        />
                          
                        </div>
                        <div className="col">
                  <TextField
                    fullWidth
                    id="outlined-text-input"
                    //label="Nombre"
                    label="CLABE"
                    type="text"
                    //name="name"
                    name="limit_registers"
                    inputProps={{ readOnly: this.props.readOnly }}
                    onBlur={handleBlur}
                    error={errors.limit_registers && touched.limit_registers}
                    helperText={touched.limit_registers && (errors.limit_registers)}
                    className={errors.limit_registers && touched.limit_registers && "error"}
                    value={values.CLABE}
                    onChange={handleChange}
                  />
                  </div>
                  <div className="col">
                  
                  <TextField
                    fullWidth
                    id="outlined-text-input"
                    //label="Nombre"
                    label="Monto"
                    type="number"
                    //name="name"
                    name="longitude"
                    inputProps={{ readOnly: this.props.readOnly }}
                    onBlur={handleBlur}
                    error={errors.longitude && touched.longitude}
                    helperText={touched.longitude && (errors.longitude)}
                    className={errors.longitude && touched.longitude && "error"}
                    value={values.amount}
                    onChange={handleChange}
                  />
                  </div>
                 

                  </div>
                  
                  <div className="row">
                    <div className="col">
                      <TextField
                      fullWidth
                      id="outlined-text-input"
                      //label="Nombre"
                      label="Estatus"
                      type="text"
                      //name="name"
                      name="number_videos"
                      inputProps={{ readOnly: this.props.readOnly }}
                      onBlur={handleBlur}
                      error={errors.number_videos && touched.number_videos}
                      helperText={touched.number_videos && (errors.number_videos)}
                      className={errors.number_videos && touched.number_videos && "error"}
                      value={this.state.estatus.name}
                      //onChange={this.searchStatus()}}                   
                    />        
                    </div>
                    <div className="col">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        format="dd/MM/yyyy HH:mm:ss"
                        margin="normal"
                        disableFuture
                        readOnly={this.props.readOnly}
                        onBlur={handleBlur}
                        error={errors.approval_date && touched.approval_date}
                        helperText={touched.approval_date && (errors.approval_date)}
                        className={errors.approval_date && touched.approval_date && "error"}  
                        label="Fecha Operacion"
                        name="approval_date"
                        value={values.approval_date}
                        onChange={date => {
                          values.date_transaction = date
                          this.forceUpdate()
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>    
                    </div>


                    <div className="col">
                      <TextField
                      fullWidth
                      id="outlined-text-input"
                      label="Folio de la operación"
                      type="number"
                      name="operation_folio"
                      onBlur={handleBlur}
                      inputProps={{ readOnly: this.props.readOnly }}
                      error={errors.operation_folio && touched.operation_folio}
                      helperText={touched.operation_folio && (errors.operation_folio)}
                      className={errors.operation_folio && touched.operation_folio && "error"}
                      value={values.operation_folio}
                      onChange={handleChange}
                    />


                    </div>
                    <FormControl fullWidth>
                        {!values.observations && (
                          <InputLabel className="pl-2" error={errors.observations && touched.observations}>Observaciones </InputLabel>
                        )}
                        <TextareaAutosize
                          //value={values.observations}
                          readOnly={this.props.readOnly}
                          name="observations"
                          type="text"
                          rowsMin={4}
                          maxLength={512}
                          onChange={handleChange}
                        />
                        {errors.observations && touched.observations && (<FormHelperText error={errors.observations && touched.observations}>{errors.observations}</FormHelperText>)}
                      </FormControl> 
                  </div>           
              {this.props.readOnly &&
                <div className="row justify-content-center mt-4 pl-4">
                  <Button className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >Volver</Button>
                </div>}
              {(!this.props.readOnly && (<div className="row justify-content-center mt-4 pl-4">
                <Button disabled={isSubmitting} className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >
                  Cancelar
                      </Button>
                <Button disabled={isSubmitting} className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleReject(values) }} >
                  Rechazar
                      </Button>
                
                <Button disabled={isSubmitting} className='btn' style={{ backgroundColor: '#4E6E58', color: 'white' }}  onClick={() => { this.handleApprove(values) }} type="submit">
                  {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                    Aprobar
                                    </Button>

                                    {console.log("****************************************+ values ", values)}

                <Button hidden={flag} /*disabled={isSubmitting}*/ className='btn' style={{ backgroundColor: '#4E6E58', color: 'white' }}  onClick={() => { this.handlePaid(values) }} type="submit">
                  {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                    Pagar
                                    </Button>
              </div>))}
            </form>
          )
        }}
      </Formik>
    );
  }
}

export default DataDisplayer;