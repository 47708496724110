import firebase from 'firebase'
import fetchData from '../services/api/FetchData'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyCYaTKjfam_qMXDnGfcdnBxScEq89VQtLk',
  authDomain: 'curious-sandbox-196209.firebaseapp.com',
  databaseURL: 'https://curious-sandbox-196209.firebaseio.com',
  projectId: 'curious-sandbox-196209',
  storageBucket: '',
  messagingSenderId: '1034032747860'
};

async function prueba(){
  try{
    let keyAuth = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5hUJ6TD";
    let bodyAuth = {key: keyAuth};
    const paramsAuth = {
      method: "POST",
      body: JSON.stringify(bodyAuth),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const authentication = await fetchData("authentication/CRM", paramsAuth);
    let tokenAuth = authentication.token;
    return tokenAuth;
  }catch(err){

  }
}

firebase.initializeApp(config);
//const auth = prueba();//firebase.auth();
const auth = firebase.auth();
//console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv auth = tojen \n", auth, prueba());

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
export {
  auth,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};