import React from "react";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import fetchData from "../../../../../services/api/FetchData";
import swal from "sweetalert";

const linkStyles = {
  textDecoration: "none",
  color: "#000000",
};

class Options extends React.Component {
  state = {
    showDialog: false,
    showDialog2: false,
  };
  handleDialogClose() {
    this.setState({ showDialog: false });
  }

  handleDialogClose2() {
    this.setState2({ showDialog2: false });
  }

  async handleDelete() {
    this.props.handleRequestClose();
    this.handleDialogClose();
    const params = {
      method: "DELETE",
    };

    const response = await fetchData(
      `payment_orders/auditors/${this.props.id}`,
      params
    );
    if (!response.success) {
      swal("Error", response.message, "error");
      return;
    }
    swal("Orden de pago eliminada", response.message, "success").then(() =>
      this.props.reloadData()
    );
  }
  render() {
    const { menuState, anchorEl, handleRequestClose, id } = this.props,
      viewLink = `payment_orders/${id}/view`,
      editLink = `payment_orders/${id}/edit`;

    return (
      <React.Fragment>
        <Dialog
          open={this.state.showDialog}
          TransitionComponent={Slide}
          onClose={this.handleDialogClose.bind(this)}
        >
          <DialogTitle>{"¡Está a punto de eliminar un registro!"}</DialogTitle>
          <DialogContent>
            <DialogContentText>¿Deseas continuar?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose.bind(this)}
              color="primary"
              variant="contained"
              className="bg-info text-white"
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleDelete.bind(this)}
              color="secondary"
              variant="contained"
              className="bg-danger text-white"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>

        {/*<Dialog open={this.state.showDialog2} TransitionComponent={Slide} onClose={this.handleDialogClose2.bind(this)}>
                    <DialogTitle>
                        {"¡Está a punto de eliminar un registro!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Realizar orden de pago
                </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose.bind(this)} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} color="primary">
                            Eliminar
                        </Button>
                    </DialogActions>
        </Dialog>*/}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={menuState}
          onClose={handleRequestClose}
          MenuListProps={{
            style: {
              width: 150,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <Link style={linkStyles} to={viewLink}>
            <MenuItem>
              <i className="zmdi zmdi-eye zmdi-hc-fw" />
              <span className="ml-2">Ver</span>
            </MenuItem>
          </Link>
          <Link style={linkStyles} to={editLink}>
            <MenuItem onClick={handleRequestClose}>
              <i className="zmdi zmdi-edit zmdi-hc-fw" />
              <span className="ml-2">Editar</span>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={() => this.setState({ showDialog: true })}
            style={{ color: "#ff0000" }}
          >
            <i className="zmdi zmdi-delete zmdi-hc-fw" />
            <span className="ml-2">Eliminar</span>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default Options;
