import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import List from './routes/List'
import View from './routes/View'
import Edit from './routes/Edit'


export default class Contracts extends React.Component {


    render() {
        const { match } = this.props
        return (
            <Switch>
                <Route exact path={`${match.url}/:rolId`} component={View} ></Route>
                <Route exact path={`${match.url}/:rolId/edit`} component={Edit} ></Route>
                <Route exact path={`${match.url}`} component={List} />
                <Redirect to="/error/error-v1" />
            </Switch>
        )
    }
}
