import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import fetchData from '../../../../../services/api/FetchData'
import swal from 'sweetalert'
import {
    
    TextField,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel
  } from "@material-ui/core";
import { Row, Col } from "reactstrap";
import { retry } from 'redux-saga/effects';


const linkStyles = {
    textDecoration: 'none',
    color: "#000000"
}

let newRoleName = "";

class Options extends React.Component {
    state = {
        showDialog: false,
        showRolPrivileges: false,
        showRolPrivilegesUpdate: false,
        privilegesListUpdate:[],
        privilegesList: [{}]
    }    

    handleDialogClose() {
        this.setState({ showDialog: false });
    }

    handleCloseDialogRolPrivileges() {
        this.setState({ showRolPrivileges: false });
    }

    handleCloseDialogRolPrivilegesUpdate() {
        this.setState({ showRolPrivilegesUpdate: false });
    }


    async getPrivileges(){
        const params = {
            method: "GET"
        }
        //let privilegesRespond = await fetchData(`roles/${this.props.id}`,params)
        let role_privilegesRespond = await fetchData(`role_privileges/privileges/name`,params)
        console.log("dddddddddddddddddddddddddd ", role_privilegesRespond)
        if(role_privilegesRespond.success){
            let rows = role_privilegesRespond.rows            
            this.setState({privilegesList: rows})  
            this.setState({privilegesListUpdate: rows})
            console.log("****************** privilegesList ", this.state.privilegesList)   
            console.log("****************** privilegesListUpdate ", this.state.privilegesListUpdate)              
        }
    }

    async componentDidMount(){
        await this.getPrivileges()
        //originalRoleName = this.props.name
    }

    handleChangeCheckbox = privilegeIdParm => (event, checked) => {
        //console.log("******* privilegesListUpdate antes de cambiar ", this.state.privilegesListUpdate)
        let copyprivilegesListUpdate = this.state.privilegesListUpdate;
        let privilegeId;
        for(let iCont = 0; iCont<copyprivilegesListUpdate.length; iCont++){
            if(copyprivilegesListUpdate[iCont].privilegeId == privilegeIdParm){
                copyprivilegesListUpdate[iCont].status = checked    
                this.setState({privilegesListUpdate: copyprivilegesListUpdate})            
            }
        }      
        console.log("******* privilegesListUpdate ", this.state.privilegesListUpdate)
    };

    async handleDelete() {
        this.props.handleRequestClose()
        this.handleDialogClose()
        let body = {
            id: this.props.data.id,
        }
        const params = {
            method: "DELETE",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const response = await fetchData(`roles`,params)
            if(!response.success){
                swal("Error", response.message, "error")
                return
            }
            swal("Rol eliminado", response.message, "success")
                .then(()=>window.location.reload(false))
    }

    handleNameChange = index =>(event) =>{
        console.log("ffffffffffffffff ", event.target.value)
        newRoleName = event.target.value
        console.log("cambiando el nombre ggg ", newRoleName)
    }

    async putRolName(){
        console.log("********* put rol name ", newRoleName, " y el viejo ", this.props.data.name)
        if(newRoleName != this.props.data.name && typeof newRoleName != "undefined" && newRoleName != null && newRoleName != ""){
            console.log("rrrrrrrrrrrrrrrrrrrrrr ", this.props.data)
            let body = {id: this.props.data.id,
                        name: newRoleName
            }
            const params = {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                'Content-Type': 'application/json'
                }
            }
            console.log("gggggggggggggggg", body)          
            let response = await fetchData(`roles`,params)
            if(response.success){
                console.log("SuccesName",response);
                return response
            }else{
                console.log("errorName",response);
                return response
            }
        }else{
            return {success:false,message:"Sin cambios"}
        }
    }

    async handleSave(){
        this.props.handleRequestClose()
        this.handleCloseDialogRolPrivilegesUpdate();
        console.log(" ya en el boton guardar ", this.state.privilegesList)
        let updateNameRespond = await this.putRolName() 
        let response = "";    
        if(updateNameRespond.success == true){
            console.log("Entro",this.state.privilegesList);
            let privileges = this.state.privilegesList            
            for(let iCont = 0; iCont<privileges.length; iCont++){
                let body = {
                            id: privileges[iCont].id,
                            status: privileges[iCont].status
                        }
                const params = {
                    method: "PUT",
                    body: JSON.stringify(body),
                    headers: {
                    'Content-Type': 'application/json'
                    }
                }
                console.log("lo que se va a enviar al put ", body)
                //response = await fetchData("roles",params)            
                response = await fetchData(`role_privileges/${privileges[iCont].id}`,params)            
                //console.log("Response", response);
                if(response.success){
                    swal("Rol actualizado", "Se ha actualizado el Rol Correctamente", "success")
                    .then(()=>window.location.reload(false))
                }else{
                    swal("Error", response.message, "success")
                }
            }              
        }else{
            //console.log("error", this.state);
            //this.handleCloseDialogRolPrivilegesUpdate();
            //this.handleCloseDialogRolPrivileges();
            //this.closeDialog();
            //console.log("error", this.state);
            swal("Ocurrio un error al actualizar el Rol", updateNameRespond.message, "error")
        }
        /*
        swal("Rol y privilegios actualizados", response.message, "success")
            .then(()=>this.props.reloadData())
       */
    }

    render() {
        const { menuState, anchorEl, handleRequestClose, id , data} = this.props,
            viewLink = `payment_orders/${id}`,
            editLink = `payment_orders/${id}/edit`

        return (
            <React.Fragment>
                <Dialog open={this.state.showDialog} TransitionComponent={Slide} onClose={this.handleDialogClose.bind(this)}>
                    <DialogTitle>
                        {"¡Está a punto de eliminar un registro!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Deseas continuar?
                </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose.bind(this)} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} color="primary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.showRolPrivileges} TransitionComponent={Slide} onClose={this.handleCloseDialogRolPrivileges.bind(this)}>
                    <DialogTitle>
                        {"Rol"}
                    </DialogTitle>
                    <DialogContent>
                        
                        <Row >
                            <FormLabel>Nombre de Rol</FormLabel>
                            <TextField
                                autoFocus
                                variant="outlined"
                                size="small"
                                margin="normal"
                                defaultValue={this.props.data.name}
                                InputProps={{
                                    readOnly: true
                                  }}
                                id="nameRol"
                                type="text"
                                fullWidth
                                name="name"                           
                            />
                        </Row>
                        <Row>
                            <Col xs="6" align="center">                        
                                <FormLabel>Privilegios</FormLabel>
                            </Col>
                            <Col xs="6" align="center">
                                <FormLabel>Asignar</FormLabel>
                            </Col>                          
                            <Col xs="6" align="center">
                            {this.state.privilegesList.map((privileges) =>
                                {if(privileges.roleId == this.props.data.id){
                                    return (
                                    <FormLabel >{privileges.namePrivilege}</FormLabel>  
                                    )
                                }}
                               
                            )}
                            </Col>
                            <Col xs="6" align="center">
                                {this.state.privilegesList.map((privileges) =>
                                    {if(privileges.roleId == this.props.data.id){
                                        return (
                                        <Checkbox color="primary"                                              
                                            checked={privileges.status}
                                        /> 
                                        )
                                    }
                                    }
                                )}                              
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogRolPrivileges.bind(this)} color="secondary">
                            Cerrar
                        </Button>
                    </DialogActions>
                 </Dialog>
                 {/*////////////////////////////////////////////////////////update //////////////////////////////////////////////////////////////////////*/}
                 <Dialog open={this.state.showRolPrivilegesUpdate} TransitionComponent={Slide} onClose={this.handleCloseDialogRolPrivilegesUpdate.bind(this)}>
                    <DialogTitle>
                        {"Rol"}
                    </DialogTitle>
                    <DialogContent>                        
                        <Row >
                            <FormLabel>Nombre de Rol</FormLabel>
                            <TextField
                                autoFocus
                                variant="outlined"
                                size="small"
                                margin="normal"
                                defaultValue={this.props.data.name}
                                onChange={this.handleNameChange()}
                                id="nameRol"
                                type="text"
                                fullWidth
                                name="name"                           
                            />
                        </Row>
                        <Row>
                            <Col xs="6" align="center">                        
                                <FormLabel>Privilegios</FormLabel>
                            </Col>
                            <Col xs="6" align="center">
                                <FormLabel>Asignar</FormLabel>
                            </Col>                          
                            <Col xs="6" align="center">
                            {this.state.privilegesList.map((privileges) =>
                            {if(privileges.roleId == this.props.data.id){
                                return (
                                <FormLabel >{privileges.namePrivilege}</FormLabel>  
                                )
                            }} 
                            )}
                            </Col>
                            <Col xs="6" align="center">
                                {this.state.privilegesList.map((privileges, index) =>
                                    {if(privileges.roleId == this.props.data.id){
                                        return (
                                            <Checkbox color="primary"    
                                            defaultChecked = {privileges.statusPrivilege}                         
                                            checked={this.state.isChecked}
                                            onChange={this.handleChangeCheckbox(privileges.privilegeId)}
                                            /> 
                                        )
                                    }
                                    }
                                )}
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave.bind(this)} color="primary">
                            Guardar
                        </Button>
                        <Button onClick={this.handleCloseDialogRolPrivilegesUpdate.bind(this)} color="secondary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Menu id="long-menu"
                    anchorEl={anchorEl}
                    open={menuState}
                    onClose={handleRequestClose}

                    MenuListProps={{
                        style: {
                            width: 150,
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    }}>
                    <MenuItem onClick={() => this.setState({ showRolPrivileges: true })}> <i className="zmdi zmdi-eye zmdi-hc-fw" /><span className="ml-2">Ver</span></MenuItem>
                    <MenuItem onClick={() => this.setState({ showRolPrivilegesUpdate: true })}><i className="zmdi zmdi-edit zmdi-hc-fw" /><span className="ml-2">Editar</span></MenuItem>
                    <MenuItem onClick={() => this.setState({ showDialog: true })} style={{ color: "#ff0000" }}><i className="zmdi zmdi-delete zmdi-hc-fw" /><span className="ml-2">Eliminar</span></MenuItem>
                    
                </Menu>
            </React.Fragment>
        );
    }

}

export default Options;

