//import storageManage from "../storage"

const fetchData = async (route, params) => {
  params.headers = {
    ...params.headers,
    //Authorization: `bearer ${storageManage.getToken()}`
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_ROUTE}/${route}`, params)
    if (response.status == 401) {
      //storageManage.removeToken()
    }
    return response.json()
  } catch (error) {
    return { success: false, message: "Ha ocurrido un problema intentando acceder al servidor" }
  }
}

export default fetchData