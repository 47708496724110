import React from 'react'
//import Datatable from '../../../../../components/Datatable'
//import Datatable from '../../../../routes/table/routes/data/Components/DataTable'
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from './rowTables'
import fetchData from '../../../../../services/api/FetchData'
//import Loader from "../../../../../components/Loader"
import Loader from "../../../../../components/CircularProgress"


export default class List extends React.Component {

  state = {
    data: [],
    headers: [
      { id: 'name', numeric: true, disablePadding: true, label: 'Nombre' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Estatus' },
    ]
  }

  setData = async (route, dataName) => {
    const params = {
      method: 'GET',
    }
    try {
      const response = await fetchData(route, params)
      if (response.success) {   
        for(let iCont = 0; iCont<response.rows.length; iCont++){
          if(response.rows[iCont].status == true){
            response.rows[iCont].statusName = "Activo"
          }else if(response.rows[iCont].status == false){
            response.rows[iCont].statusName = "Inactivo"
          }
        }    
        let data = response.rows
        this.setState({
          ...this.state,
          [dataName]: data
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

   componentDidMount() {
    this.setData(`roles`, "data")
  }

  reloadData = () => this.props.history.push("/app/dashboard/crm")

  render() {
    return (
      this.state.data.length != 0 ?
      <div className="container">
        <div className="row">
          <div className="col-md-12  mr-2">
            <Datatable RowComponent={RowTable} data={this.state.data} headers={this.state.headers} reloadData={this.reloadData} title="Roles"/>
          </div>
        </div>
        </div>        
        :
        <Loader/>

    )
  }

}


