import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import Widgets from "./widgets";
import Metrics from "./metrics";
import Components from "./components";
import Icons from "./icons";
import Form from "./form";
import Editors from "./editors";
import Pickers from "./pickers";
import Extensions from "./extensions";
import Table from "./table";
import Chart from "./charts";
import Map from "./map";
import Calendar from "./calendar";
import TimeLine from "./timeLine";
import CustomViews from "./customViews";
import ExtraElements from "./extraElements";
import eCommerce from "./eCommerce";
import AppModule from "./appModule";
import ExtraPages from "./extraPages";
import asyncComponent from "../../util/asyncComponent";
import SocialApps from "./socialApps";
import { withRouter } from "react-router";
import MenuLevels from "./menuLevels";
import Transfers from "./transfers";
import Roles from "./Roles";
import Privileges from "./Privilegios"

const Routes = ({ match }) =>
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/social-apps`} component={SocialApps}/>
    <Route path={`${match.url}/components`} component={Components}/>
    <Route path={`${match.url}/icons`} component={Icons}/>
    <Route path={`${match.url}/form`} component={Form}/>
    <Route path={`${match.url}/editor`} component={Editors}/>
    <Route path={`${match.url}/pickers`} component={Pickers}/>
    <Route path={`${match.url}/extensions`} component={Extensions}/>
    <Route path={`${match.url}/table`} component={Table}/>
    <Route path={`${match.url}/chart`} component={Chart}/>
    <Route path={`${match.url}/map`} component={Map}/>
    <Route path={`${match.url}/calendar`} component={Calendar}/>
    <Route path={`${match.url}/time-line`} component={TimeLine}/>
    <Route path={`${match.url}/custom-views`} component={CustomViews}/>

    <Route path={`${match.url}/widgets`} component={Widgets}/>
    <Route path={`${match.url}/metrics`} component={Metrics}/>
    <Route path={`${match.url}/extra-elements`} component={ExtraElements}/>
    <Route path={`${match.url}/ecommerce`} component={eCommerce}/>
    <Route path={`${match.url}/app-module`} component={AppModule}/>
    <Route path={`${match.url}/menu-levels`} component={MenuLevels}/>
    <Route path={`${match.url}/to-do`}
           component={asyncComponent(() => import("./todo/basic"))}/>
    <Route path={`${match.url}/to-do-redux`}
           component={asyncComponent(() => import("./todo/redux"))}/>
    <Route path={`${match.url}/mail`}
           component={asyncComponent(() => import("./mail/basic"))}/>
    <Route path={`${match.url}/mail-redux`}
           component={asyncComponent(() => import("./mail/redux"))}/>
    <Route path={`${match.url}/chat`}
           component={asyncComponent(() => import("./chatPanel/basic"))}/>
    <Route path={`${match.url}/chat-redux`}
           component={asyncComponent(() => import("./chatPanel/redux"))}/>
    <Route path={`${match.url}/contact`}
           component={asyncComponent(() => import("./contact/basic"))}/>
    <Route path={`${match.url}/contact-redux`}
           component={asyncComponent(() => import("./contact/redux"))}/>
    <Route path={`${match.url}/missions`}
           component={asyncComponent(() => import("./Missions"))}/>
    <Route path={`${match.url}/extra-pages`} component={ExtraPages}/>
    <Route path={`${match.url}/create-mission`}
           component={asyncComponent(() => import("./createMission"))}/>
    <Route path={`${match.url}/create-question`}
           component={asyncComponent(() => import("./CreateQuestion"))}/>
    <Route path={`${match.url}/questions`}
           component={asyncComponent(() => import("./Questions"))}/>
    <Route path={`${match.url}/create-notification`}
           component={asyncComponent(() => import("./CreateNotification"))}/>
    <Route path={`${match.url}/notifications`}
           component={asyncComponent(() => import("./Notifications"))}/>
    <Route path={`${match.url}/list-auditors`}
           component={asyncComponent(() => import("./Auditores"))}/>
    <Route path={`${match.url}/list-users`}
           component={asyncComponent(() => import("./Users"))}/>
     <Route path={`${match.url}/evaluations`}
           component={asyncComponent(() => import("./evaluations"))}/>
     <Route path={`${match.url}/create-evaluation`}
           component={asyncComponent(() => import("./createPaymentOrder"))}/>     
    <Route path={`${match.url}/payment_orders`} component={Transfers}/> 
    <Route path={`${match.url}/roles`} component={Roles}/>  
    <Route path={`${match.url}/privileges`} component={Privileges}/>      

    
  </Switch>;


export default withRouter(Routes);
