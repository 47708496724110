const jwt = require("jsonwebtoken");
const crypto = require("crypto-js");
const bodyParser = require("body-parser");

const baseUrl = process.env.REACT_APP_API_ROUTE;

const encryptData = async (data) => {
   
    //convert JSON to string
    let stringData = JSON.stringify(data);
    var textToEncrypt = stringData;            
    //get key
    let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h"
    var password = key; 

    const encrypt = function (text) {
        return (crypto.AES.encrypt(text, password)).toString();
    }

    var cipherText = encrypt(textToEncrypt);
    return cipherText
}

const decipherData = async (data) => {
   
    //convert JSON to string
    let stringData = JSON.stringify(data);
    var textToEncrypt = stringData;            
    //get key
    let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h"
    var password = key; 

    //decipher data
    const decrypt = function (ciphertext) {
        var bytes  = crypto.AES.decrypt(ciphertext, password);
        return bytes.toString(crypto.enc.Utf8);
    }

    //parse data
    var uncipherText = decrypt(data);
    //console.log("security", uncipherText);
    let text = JSON.parse(uncipherText);

    return text
}

export {
  encryptData,
  decipherData
};
