import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import fetchData from '../../../../../services/api/FetchData'
import swal from 'sweetalert'
import {
    
    TextField,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel
  } from "@material-ui/core";
import { Row, Col } from "reactstrap";
import { retry } from 'redux-saga/effects';


const linkStyles = {
    textDecoration: 'none',
    color: "#000000"
}

let newRoleName = "";

class Options extends React.Component {
    state = {
        showDialog: false,
        showRolPrivileges: false,
        showRolPrivilegesUpdate: false,
        showPrivilegesCreate: false,
        privilegesListUpdate:[],
        privilegesList: [{}]
    }    

    handleDialogClose() {
        this.setState({ showDialog: false });
    }

    handleCloseDialogRolPrivileges() {
        this.setState({ showRolPrivileges: false });
    }

    handleCloseDialogRolPrivilegesUpdate() {
        this.setState({ showRolPrivilegesUpdate: false });
    }

    handleCloseDialogPrivilegesCreate(){
        this.setState({ showPrivilegesCreate: false });
    }

    componentDidMount(){
        this.setState({privilegesList: this.props.data})
        this.setState({privilegesListUpdate: this.props.data})
    }

    handleChangeCheckbox = privilegeIdParm => (event, checked) => {
        //console.log("******* privilegesListUpdate antes de cambiar ", this.state.privilegesListUpdate)
        let copyprivilegesListUpdate = this.state.privilegesListUpdate;
        copyprivilegesListUpdate.status = checked    
        this.setState({privilegesListUpdate: copyprivilegesListUpdate})  
        console.log("******* privilegesListUpdate ", this.state.privilegesListUpdate)
    };

    async handleDelete() {
        this.props.handleRequestClose()
        this.handleDialogClose()
        let body = {
            id: this.state.privilegesListUpdate.id
        }
        const params = {
            method: "DELETE",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const response = await fetchData(`privileges`,params)
            if(!response.success){
                swal("Error", response.message, "error")
            return
            }
            swal("Privilegio eliminado", response.message, "success")
                .then(()=>this.props.reloadData())

    }

    handleNameChange = index =>(event) =>{
        console.log("ffffffffffffffff ", event.target.value)
        newRoleName = event.target.value
        console.log("cambiando el nombre ggg ", newRoleName)
    }          

    async handleSave(){
        this.props.handleRequestClose()
        this.handleDialogClose()
        console.log(" ya en el boton guardar ", this.state.privilegesListUpdate)
        let namePrivilege;
        if(newRoleName != this.props.data.name && typeof newRoleName != "undefined" && newRoleName != null && newRoleName != ""){
            namePrivilege = newRoleName
        }else{
            namePrivilege = this.props.data.name
        }
       
        let body = {
            id: this.state.privilegesListUpdate.id,
            status: this.state.privilegesListUpdate.status,
            name: namePrivilege
        }
        const params = {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        console.log("lo que se va a enviar al put ", body)
        let response = await fetchData("privileges",params)  
        if(response.success){
            swal("Privilegio actualizado", response.message, "success")
            .then(()=>this.props.reloadData())
        }else{
            swal("Error al actualizar el privilegio", response.message, "error")
            .then(()=>this.props.reloadData())
        }                    
    }

    async handleCreate(){
        this.props.handleRequestClose()
        this.handleDialogClose()
        console.log(" ya en el boton crear ", this.state.privilegesListUpdate)
        let namePrivilege;
        if(newRoleName != this.props.data.name && typeof newRoleName != "undefined" && newRoleName != null && newRoleName != ""){
            namePrivilege = newRoleName
        }
       
        let body = {
            status: this.state.privilegesListUpdate.status,
            name: namePrivilege
        }
        const params = {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        console.log("lo que se va a enviar al put ", body)
        let response = await fetchData("privileges",params)  
        if(response.success){
            swal("Privilegio creado", response.message, "success")
            .then(()=>this.props.reloadData())
        }else{
            swal("Error al creado el privilegio", response.message, "error")
            .then(()=>this.props.reloadData())
        }                   
    }


    render() {
        const { menuState, anchorEl, handleRequestClose, id , data} = this.props,
            viewLink = `payment_orders/${id}`,
            editLink = `payment_orders/${id}/edit`

        return (
            <React.Fragment>
                <Dialog open={this.state.showDialog} TransitionComponent={Slide} onClose={this.handleDialogClose.bind(this)}>
                    <DialogTitle>
                        {"¡Está a punto de eliminar un registro!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Deseas continuar?
                </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose.bind(this)} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} color="primary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.showRolPrivileges} TransitionComponent={Slide} onClose={this.handleCloseDialogRolPrivileges.bind(this)}>
                    <DialogTitle>
                        {"Privilegio"}
                    </DialogTitle>
                    <DialogContent>
                        
                        <Row >
                            <FormLabel>Nombre del privilegio</FormLabel>
                            <TextField
                                autoFocus
                                variant="outlined"
                                size="small"
                                margin="normal"
                                defaultValue={this.props.data.name}
                                InputProps={{
                                    readOnly: true
                                  }}
                                id="nameRol"
                                type="text"
                                fullWidth
                                name="name"                           
                            />
                        </Row>
                        <br>
                        </br>
                        <Row>                        
                            <Col xs="6" align="center">
                                <FormLabel >Estado: </FormLabel>   
                            </Col>
                            <Col xs="6" align="center">
                                <FormLabel >{this.props.data.statusName}</FormLabel>  
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogRolPrivileges.bind(this)} color="secondary">
                            Cerrar
                        </Button>
                    </DialogActions>
                 </Dialog>
                 {/*////////////////////////////////////////////////////////update //////////////////////////////////////////////////////////////////////*/}
                 <Dialog open={this.state.showRolPrivilegesUpdate} TransitionComponent={Slide} onClose={this.handleCloseDialogRolPrivilegesUpdate.bind(this)}>
                    <DialogTitle>
                        {"Privilegio"}
                    </DialogTitle>
                    <DialogContent>                        
                        <Row >
                            <FormLabel>Nombre del privilegio</FormLabel>
                            <TextField
                                autoFocus
                                variant="outlined"
                                size="small"
                                margin="normal"
                                defaultValue={this.props.data.name}
                                onChange={this.handleNameChange()}
                                id="nameRol"
                                type="text"
                                fullWidth
                                name="name"                           
                            />
                        </Row>
                        <br>
                        </br>
                        <Row>         
                            <Col xs="6" align="center">
                                <FormLabel >Estado</FormLabel>  
                            </Col>
                            <Col xs="6" align="center">
                              
                                            <Checkbox color="primary"    
                                            defaultChecked = {this.props.data.status}                         
                                            checked={this.state.isChecked}
                                            onChange={this.handleChangeCheckbox(this.props.data.id)}
                                            /> 
        
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave.bind(this)} color="primary">
                            Guardar
                        </Button>
                        <Button onClick={this.handleCloseDialogRolPrivilegesUpdate.bind(this)} color="secondary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Menu id="long-menu"
                    anchorEl={anchorEl}
                    open={menuState}
                    onClose={handleRequestClose}

                    MenuListProps={{
                        style: {
                            width: 150,
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    }}>
                    <MenuItem onClick={() => this.setState({ showRolPrivileges: true })}> <i className="zmdi zmdi-eye zmdi-hc-fw" /><span className="ml-2">Ver</span></MenuItem>
                    <MenuItem onClick={() => this.setState({ showRolPrivilegesUpdate: true })}><i className="zmdi zmdi-edit zmdi-hc-fw" /><span className="ml-2">Editar</span></MenuItem>
                    <MenuItem onClick={() => this.setState({ showDialog: true })} style={{ color: "#ff0000" }}><i className="zmdi zmdi-delete zmdi-hc-fw" /><span className="ml-2">Eliminar</span></MenuItem>
                    
                </Menu>
            </React.Fragment>
        );
    }

}

export default Options;

