import React from 'react'
import PaymentOrderForm from '../../../createPaymentOrder/components/PaymentOrderForm'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import fetchData from '../../../../../services/api/FetchData'
import Loader from "../../../../../components/CircularProgress"
import swal from 'sweetalert';

export default class View extends React.Component {

    state = {
        payment_order: null,
    }   

    handleReject = async values => {       
        console.log("\n\n\nhandle reject", values);
        let body = {
            id: values.id,
            statusId: 12,
            observations: values.observations,
            operation_folio: values.operation_folio
            //userid
        }

        //get key
        let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
        var password = key;
        let stringData = JSON.stringify(body);
        var textToEncrypt = stringData;

        const encrypt = function(text) {
            return crypto.AES.encrypt(text, password).toString();
        };

        var cipherText = await encrypt(textToEncrypt);
        console.log("cipherText", cipherText);

        let bodyToSend = {
            data: cipherText
        }

        const params = {
            method: 'PUT',
            body: JSON.stringify(bodyToSend),
            headers: {
            'Content-Type': 'application/json'
            }
        }
        const response = await fetchData("payment_orders", params);
        console.log("responde tttttttttttttttttttttt ", response)
            if (!response.success) {
                swal("Error", response.message, "error")
                    .then(() => this.props.history.push(this.props.match.url))
                return
            }
            swal("Orden de pago actualizada", "La orden de pago se ha actualizado correctamente", "success")
                .then(() => this.props.history.push("/app/payment_orders"))      
    }

    handleApprove = async values => {       
        console.log("\n\n\nhandle reject", values);
        let body = {
            id: values.id,
            statusId: 11,
            observations: values.observations,
            operation_folio: values.operation_folio
            //userid
        }

        //get key
        let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
        var password = key;
        let stringData = JSON.stringify(body);
        var textToEncrypt = stringData;

        const encrypt = function(text) {
            return crypto.AES.encrypt(text, password).toString();
        };

        var cipherText = await encrypt(textToEncrypt);
        console.log("cipherText", cipherText);

        let bodyToSend = {
            data: cipherText
        }

        const params = {
            method: 'PUT',
            body: JSON.stringify(bodyToSend),
            headers: {
            'Content-Type': 'application/json'
            }
        }
        const response = await fetchData("payment_orders", params);
        console.log("responde tttttttttttttttttttttt ", response)
            if (!response.success) {
                swal("Error", response.message, "error")
                    .then(() => this.props.history.push(this.props.match.url))
                return
            }
            swal("Orden de pago actualizada", "La orden de pago se ha actualizado correctamente", "success")
                .then(() => this.props.history.push("/app/payment_orders"))  
    }

    handlePaymentOrderIsPaid = async values => {       
        console.log("\n\n\n ************************** handle reject", values);
        let body = {
            id: values.id,
            statusId: 13,
            observations: values.observations,
            operation_folio: values.operation_folio
            //userid
        }

        //get key
        let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
        var password = key;
        let stringData = JSON.stringify(body);
        var textToEncrypt = stringData;

        const encrypt = function(text) {
            return crypto.AES.encrypt(text, password).toString();
        };

        var cipherText = await encrypt(textToEncrypt);
        console.log("cipherText", cipherText);

        let bodyToSend = {
            data: cipherText
        }

        const params = {
            method: 'PUT',
            body: JSON.stringify(bodyToSend),
            headers: {
            'Content-Type': 'application/json'
            }
        }
        const response = await fetchData("payment_orders", params);
        console.log("responde tttttttttttttttttttttt ", response)
            if (!response.success) {
                swal("Error", response.message, "error")
                    .then(() => this.props.history.push(this.props.match.url))
                return
            }
            swal("Orden de pago actualizada", "La orden de pago se ha actualizado correctamente", "success")
                .then(() => this.props.history.push("/app/payment_orders"))  
    }


    getData = async (route, dataName) => {

        try{
            let body = {
                payment_orderId: this.props.match.params.paymet_ordersId
            }               
            const params = {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await fetchData(route, params)
            if (response.success) {
                let paymentOrdersRespond = response.payment_orders;
                console.log("++++++++++++++++++++++++++ al fin ",response);
                const crypto = require("crypto-js"); 
                let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
                var password = key; 

                //decipher data
                const decrypt = function (ciphertext) {
                    var bytes  = crypto.AES.decrypt(ciphertext, password);
                    return bytes.toString(crypto.enc.Utf8);
                }
        
                //parse data
                var uncipherText = decrypt(paymentOrdersRespond);
                let text = JSON.parse(uncipherText);
                let data = text;
                console.log("+++++++++++++++ data", data[data.length-1]);
                this.setState({
                    [dataName]: data[data.length-1]
                })
            }else{
                console.log("++++++++++++++++++++++++++ jajajaja",);
            }
        }catch(err){
            console.log("++++++++++++++++++++++++++ Error: ",err);
        }       
    }  


    handleSubmit = async values => {
        const data = new FormData()
        for (let key in values) {
            if (values[key] !== null) {
                console.log(key, values[key])
                data.append(key, values[key])
            }
        }
        const params = {
            method: 'PUT',
            body: data
        }
        const response = await fetchData(`payment_orders/${values.id}`, params)
        console.log("json de la respuesta ",response)
        if (!response.success) {
            swal("Error", response.message, "error")
            return
        }
        swal("Mision editada", response.message, "success")
            .then(() => this.handleCancel())
    }

    async componentDidMount() {
        await this.getData(`payment_orders/one`, "payment_order")
    }

    handleCancel = () => this.props.history.push("/app/payment_orders")

    render() {
        console.log(this.state.payment_order)
        return (
            this.state.payment_order ?
                <Card className="container-fluid p-4 pl-5">
                    <h1>Editar orden de pago</h1>
                    <Divider className="mb-4" />
                    <Card className="col-12 p-4 pl-5 mr-4">
                        <PaymentOrderForm readOnly payment_order={this.state.payment_order} onSubmit={this.handleSubmit} onCancel={this.handleCancel} onReject={this.handleReject} onApprove={this.handleApprove} onPaid={this.handlePaymentOrderIsPaid} />
                    </Card>
                </Card>
                :
                <Loader />
        )
    }
}