import React from 'react'
//import Datatable from '../../../../../components/Datatable'
//import Datatable from '../../../../routes/table/routes/data/Components/DataTable'
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from './rowTables'
import fetchData from '../../../../../services/api/FetchData'
//import Loader from "../../../../../components/Loader"
import Loader from "../../../../../components/CircularProgress"
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert'
import {
    
  TextField,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel
} from "@material-ui/core";

import Slide from '@material-ui/core/Slide';
import { Row, Col } from "reactstrap";


let newRoleName = "";
export default class List extends React.Component {

  state = {
    data: [],
    headers: [
      { id: 'name', numeric: true, disablePadding: true, label: 'Nombre' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Estatus' },
    ],
    showPrivilegesCreate: false,
    showDialog: false,
    showRolPrivileges: false,
    showRolPrivilegesUpdate: false,
    showPrivilegesCreate: false,
    privilegesListUpdate:[],
    privilegesList: [{}]
  }


  handleNameChange = index =>(event) =>{
    console.log("ffffffffffffffff ", event.target.value)
    newRoleName = event.target.value
    console.log("cambiando el nombre ggg ", newRoleName)
  }   
  
  handleChangeCheckbox = privilegeIdParm => (event, checked) => {
    //console.log("******* privilegesListUpdate antes de cambiar ", this.state.privilegesListUpdate)
    let copyprivilegesListUpdate = this.state.privilegesListUpdate;
    copyprivilegesListUpdate.status = checked    
    this.setState({privilegesListUpdate: copyprivilegesListUpdate})  
    console.log("******* privilegesListUpdate ", this.state.privilegesListUpdate)
};


async handleCreate(){
  console.log(" ya en el boton crear ", this.state.privilegesListUpdate)
  let namePrivilege;
  if(newRoleName != this.state.data.name && typeof newRoleName != "undefined" && newRoleName != null && newRoleName != ""){
      namePrivilege = newRoleName
  }
 
  let body = {
      status: this.state.privilegesListUpdate.status,
      name: namePrivilege
  }
  const params = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
          'Content-Type': 'application/json'
      }
  }
  console.log("lo que se va a enviar al put ", body)
  let response = await fetchData("privileges",params)  
  if(response.success){
      swal("Privilegio creado", "Registro de privilegio exitoso", "success")
      .then(()=>this.reloadData())
  }else{
      swal("Error al creado el privilegio", response.message, "error")
      .then(()=>this.reloadData())
  }                   
}

  setData = async (route, dataName) => {
    const params = {
      method: 'GET',
    }
    try {
      const response = await fetchData(route, params)
      if (response.success) {   
        for(let iCont = 0; iCont<response.rows.length; iCont++){
          if(response.rows[iCont].status == true){
            response.rows[iCont].statusName = "Activo"
          }else if(response.rows[iCont].status == false){
            response.rows[iCont].statusName = "Inactivo"
          }
        }    
        let data = response.rows
        this.setState({
          ...this.state,
          [dataName]: data
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

   componentDidMount() {
    this.setData(`privileges`, "data")
  }

  reloadData = () => this.props.history.push("app/privileges")

  handleCloseDialogPrivilegesCreate(){
    this.setState({ showPrivilegesCreate: false });
  }

  render() {
    return (
      this.state.data.length != 0 ?
      <div className="container">
        <div className="row">
          <div className="col-md-12  mr-2">
            <Datatable RowComponent={RowTable} data={this.state.data} headers={this.state.headers} reloadData={this.reloadData} title="Privilegios"/>
            <Button onClick={() => this.setState({ showPrivilegesCreate: true })} variant="contained" color="primary" className="text-white">Crear privilegio</Button>
            {/*//////////////////////////////////////////////////////// create //////////////////////////////////////////////////////////////////////*/}
            <Dialog open={this.state.showPrivilegesCreate} TransitionComponent={Slide} onClose={this.handleCloseDialogPrivilegesCreate.bind(this)}>
                    <DialogTitle>
                        {"Privilegio"}
                    </DialogTitle>
                    <DialogContent>                        
                        <Row >
                            <FormLabel>Nombre del privilegio</FormLabel>
                            <TextField
                                autoFocus
                                variant="outlined"
                                size="small"
                                margin="normal"
                                onChange={this.handleNameChange()}
                                id="nameRol"
                                type="text"
                                fullWidth
                                name="name"                           
                            />
                        </Row>
                        <br>
                        </br>
                        <Row>         
                            <Col xs="6" align="center">
                                <FormLabel >Estado</FormLabel>  
                            </Col>
                            <Col xs="6" align="center">
                              
                                            <Checkbox color="primary"                            
                                            checked={this.state.isChecked}
                                            onChange={this.handleChangeCheckbox(this.state.data.id)}
                                            /> 
        
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCreate.bind(this)} color="primary">
                            Guardar
                        </Button>
                        <Button onClick={this.handleCloseDialogPrivilegesCreate.bind(this)} color="secondary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
          </div>
        </div>
        </div>        
        :
        <Loader/>

    )
  }

}


