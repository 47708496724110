import { encryptData, decipherData } from "./Security.js";

const jwt = require("jsonwebtoken");
const crypto = require("crypto-js");
const bodyParser = require("body-parser");

const baseUrl = process.env.REACT_APP_API_ROUTE;

const authentication = async (key) => {
  const params = {
    key: key,
  };
  const options = {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log("parametros authentication", options);
  try {
    let data = await fetch(baseUrl + "/authentication/CRM", options);
    //console.log("data tokenData", data);
    data = await data.json();
    //console.log("datajson tokenData", data);
    if (data.success) {
      return data.token;
    }
  } catch (err) {
    console.log("error tokenData", err);
    throw err;
  }
};

const evaluationId = async (evaluationId) => {
  try {
    const params = {
      evaluationId: evaluationId,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let data = await fetch(baseUrl + "/evaluations/start", options);
    console.log("data evaluationIdData", data);
    data = await data.json();
    console.log("datajson evaluationIdData", data);

    return data;
  } catch (err) {
    console.log("error evaluationIdData", err);
    throw err;
  }
};

const loginData = async (email, password, access_token) => {
  try {
    console.log("loginData", email, password, access_token);
    let dataLogin = {
      email: email,
      password: password,
      access_token: access_token,
    };
    console.log("dataLogin******", dataLogin);
    let stringData = JSON.stringify(dataLogin);
    var textToEncrypt = stringData;
    let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
    var password = key;

    const encrypt = function(text) {
      return crypto.AES.encrypt(text, password).toString();
    };

    var cipherText = encrypt(textToEncrypt);
    //var encryptingData = await this.encryptData(dataLogin);
    console.log("cipherText", cipherText);

    const params = {
      data: cipherText,
    };
    const options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let data = await fetch(baseUrl + "/login/CRM", options);
    console.log("data loginData", data);
    data = await data.json();
    console.log("datajson loginData", data);
  } catch (err) {
    console.log("error loginData", err);
    throw err;
  }
};

const getAuditors = async () => {
  try {
    const options = {
      headers: {},
    };
    let data = await fetch(baseUrl + "/auditors", options);
    data = await data.json();
    //console.log("getAuditors", data);
    if (data.success) {
      //set key to use
      //get key
      let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
      var password = key;
      //decipher data
      const decrypt = function(ciphertext) {
        var bytes = crypto.AES.decrypt(ciphertext, password);
        return bytes.toString(crypto.enc.Utf8);
      };

      //parse data
      var uncipherText = decrypt(data.auditors);
      //console.log("getAuditors text", uncipherText);
      let text = JSON.parse(uncipherText);

      for (let i = 0; i < text.length; i++) {
        // let password = await decipherData(text[i].password);
        // text[i].password = password;
        // try {
        //   //console.log("id text>>>>", text[i].statusId);
        //   let statusResponse = await getStatusAuditor(text[i].statusId);
        //   console.log("statusName***", statusResponse[statusResponse.length-1].name);
        //   let statusName = statusResponse[statusResponse.length-1].name;
        //   text[i].statusName = statusName;
        // } catch (error) {
        //   console.log("Error status", error)
        // }
        let statusId = text[i].statusId;
        /*
        if(text[i].id == 82){
          let despassword = await decipherData(text[i].password);
          text[i].password = despassword;        
          console.log("text id", text[i]);
        }
        */
        if (statusId == 1) {
          text[i].statusName = "Activo";
        } else if (statusId == 2) {
          text[i].statusName = "Inactivo";
        } else if (statusId == 3) {
          text[i].statusName = "En Proceso";
        } else if (statusId == 4) {
          text[i].statusName = "Finalizado";
        } else if (statusId == 5) {
          text[i].statusName = "Cancelada";
        }
      }
      //console.log("text with statusName", text);
      return text;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getUsers = async () => {
  try {
    const params = {
      headers: {},
    };
    let data = await fetch(baseUrl + "/user", params);
    data = await data.json();
    console.log("getUser", data);
    for (let i = 0; i < data.users.length; i++) {
      //console.log("Passwprd", data.users[i].password);
      let password = await decipherData(data.users[i].password);
      data.users[i].password = password;
      //console.log("User", data.users[i].username);
      //console.log("passwords decipher", data.users[i].password);
    }
    //console.log("Salio del for");
    if (data.success) {
      if (data.users.length == 0) {
        throw "Error en recuperar datos";
      }

      let rolesRespond = await fetch(baseUrl + "/roles", params);
      rolesRespond = await rolesRespond.json();

      if (rolesRespond.success) {
        for (let i = 0; i < data.users.length; i++) {
          let user = data.users[i];

          const role = rolesRespond.rows.filter(
            (row) => row.id == user.roleId
          )[0];
          data.users[i].roleName = role.name;
        }
      }
      console.log(data.users);
      return data.users;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// const getRoles = async () => {
//   try {
//     const params = {
//       headers: {},
//     };
//     let data = await fetch(baseUrl + "/roles", params);
//     data = await data.json();
//     console.log("getRoles", data);

//     if (data.success) {
//       console.log(data.rows);
//       return data.rows;
//     } else {
//       throw data.message;
//     }
//   } catch (err) {
//     console.log(err);
//     throw err;
//   }
// };

// const getEvaluationMissions = async () => {
//   try {
//     const params = {
//       headers: {},
//     };
//     let data = await fetch(baseUrl + "/evaluations2/data", params);
//     data = await data.json();
//     console.log("getEvaluations", data);

//     if (data.success) {
//       console.log(data.data);
//       return data.data;
//     } else {
//       throw data.message;
//     }
//   } catch (err) {
//     console.log(err);
//     throw err;
//   }
// };

// const getDataToStartEvaluation = async (evaluationId) => {
//   try {
//     const params = {
//       evaluationId: evaluationId,
//     };

//     const options = {
//       method: "POST",
//       body: JSON.stringify(params),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     let data = await fetch(baseUrl + "/evaluations/start", options);
//     data = await data.json();
//     //console.log("****************", data);

//     if (data.success) {
//       //console.log("******************************* 2",data.data);
//       return data.data;
//     } else {
//       throw data.message;
//     }
//   } catch (err) {
//     console.log(err);
//     throw err;
//   }
// };

// const getMultimedias = async (id) => {
//   try {
//     const params = {
//       headers: {},
//     };
//     let data = await fetch(baseUrl + `/multimedias/evaluation/${id}`, params);
//     data = await data.json();
//     console.log("getMultimedias", data);

//     if (data.success) {
//       console.log(data.rows);
//       return data.rows;
//     } else {
//       throw data.message;
//     }
//   } catch (err) {
//     console.log(err);
//     throw err;
//   }
// };

const getStatusAuditor = async (id) => {
  try {
    const params = {
      headers: {},
    };
    let data = await fetch(baseUrl + "/status", params);
    data = await data.json();

    if (data.success) {
      return data.rows;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteAuditor = async (id) => {
  try {
    const params = {
      id: id,
    };

    const options = {
      method: "DELETE",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = await fetch(baseUrl + "/auditors", options);
    data = await data.json();
    if (data.success) {
      return true;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const reactiveAuditor = async (id) => {
  try {
    const params = {
      id: id,
      statusId: 1,
    };

    let stringData = JSON.stringify(params);
    var textToEncrypt = stringData;

    //get key
    let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
    var password = key;

    const encrypt = function(text) {
      return crypto.AES.encrypt(text, password).toString();
    };

    var cipherText = encrypt(textToEncrypt);
    var dataUpdate = { data: cipherText };

    const options = {
      method: "PUT",
      body: JSON.stringify(dataUpdate),
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log("update auditor options", options);
    //console.log("data Update", data);
    let data = await fetch(baseUrl + "/auditors", options);

    data = await data.json();
    if (data.success) {
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

// const validateUser = () => {
//   return 1;
// };

const updateAuditor = async (id, updateData) => {
  console.log("updateData", updateData);
  const params = {
    id: id,
    name: updateData.name,
    last_name: updateData.last_name,
    second_name: updateData.second_name,
    date_of_birth: updateData.date_of_birth,
    phone: updateData.phone,
    email: updateData.email,
    password: updateData.password,
    //TyC: updateData.TyC,
    sex: updateData.sex,
    //levelId: updateData.levelId,
    statusId: updateData.statusId,
    //notification: updateData.notifications,
    //balance: updateData.balance
  };
  console.log("parametros updateAuditor", params);

  //console.log(options);

  //console.log("data PUT Auditor", data);

  let stringData = JSON.stringify(params);
  var textToEncrypt = stringData;

  //get key
  let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
  var password = key;

  const encrypt = function(text) {
    return crypto.AES.encrypt(text, password).toString();
  };

  var cipherText = encrypt(textToEncrypt);
  var dataUpdate = { data: cipherText };

  const options = {
    method: "PUT",
    body: JSON.stringify(dataUpdate),
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log("update auditor options", options);
  //console.log("data Update", data);
  let data = await fetch(baseUrl + "/auditors", options);

  data = await data.json();
  if (data.success) {
  } else {
    throw data.message;
  }
};

const updateBalanceAuditor = async (id, updateData) => {
  //console.log("updateData", updateData);
  const params = {
    id: id,
    balance: updateData.balance,
  };
  console.log("balance updateAuditor", params);

  //console.log(options);

  //console.log("data PUT Auditor", data);

  let stringData = JSON.stringify(params);
  var textToEncrypt = stringData;

  //get key
  let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
  var password = key;

  const encrypt = function(text) {
    return crypto.AES.encrypt(text, password).toString();
  };

  var cipherText = encrypt(textToEncrypt);
  var dataUpdate = { data: cipherText };

  const options = {
    method: "PUT",
    body: JSON.stringify(dataUpdate),
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log("update auditor options", options);
  //console.log("data Update", data);
  let data = await fetch(baseUrl + "/auditors", options);

  data = await data.json();
  if (data.success) {
  } else {
    throw data.message;
  }
};

const updateUser = async (id, updateData) => {
  const params = {
    id: id,
    username: updateData.username,
    email: updateData.email,
    //password: updateData.password,
    //create_time: updateData.create_time,
    roleId: updateData.roleId,
    statusId: updateData.statusId,
    //createdAt: updateData.createdAt,
  };

  console.log("CAMBIOO PASS", typeof updateData.password === "undefined");
  if (typeof updateData.password === "undefined") {
  } else {
    params.password = updateData.password;
  }

  console.log(params);

  let stringData = JSON.stringify(params);
  var textToEncrypt = stringData;

  //get key
  let key = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h";
  var password = key;

  const encrypt = function(text) {
    return crypto.AES.encrypt(text, password).toString();
  };

  var cipherText = encrypt(textToEncrypt);
  var dataUpdate = { data: cipherText };

  const options = {
    method: "PUT",
    body: JSON.stringify(dataUpdate),
    headers: {
      "Content-Type": "application/json",
    },
  };
  // console.log(options);
  let data = await fetch(baseUrl + "/user", options);
  // console.log(data);

  data = await data.json();
  if (data.success) {
  } else {
    throw data.message;
  }
};

const updateEvaluation = async (updateData) => {
  let evaluationId = updateData.evaluationId;
  const params = {
    comments: updateData.comments,
    statusId: 7,
    evaluatorId: getUserSystemId(),
  };
  console.log(params);

  const options = {
    method: "PUT",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log(options);
  let data = await fetch(baseUrl + `/evaluations/${evaluationId}`, options);
  console.log("DATA updateEvaluation", data);
  data = await data.json();

  if (data.success) {
    //console.log(data.rows);
    return data;
  } else {
    throw data.message;
  }
};

const getRoles = async () => {
  try {
    const params = {
      headers: {},
    };
    let data = await fetch(baseUrl + "/roles", params);
    data = await data.json();
    console.log("getRoles", data);

    if (data.success) {
      console.log(data.rows);
      return data.rows;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const rejectEvaluation = async (updateData) => {
  let evaluationId = updateData.evaluationId;
  const params = {
    comments: updateData.comments,
    statusId: 8,
    evaluatorId: getUserSystemId(),
  };
  console.log(params);

  const options = {
    method: "PUT",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log(options);
  let data = await fetch(baseUrl + `/evaluations/${evaluationId}`, options);
  data = await data.json();
  console.log(data);

  if (data.success) {
    return data;
  } else {
    throw data.message;
  }
};

const getEvaluationMissions = async () => {
  try {
    const params = {
      headers: {},
    };
    let data = await fetch(baseUrl + "/evaluations2/data", params);
    data = await data.json();
    console.log("getEvaluations", data);

    if (data.success) {
      for (let i = 0; i < data.data.length; i++) {
        let status = data.data[i].status;
        if (status == "Pendiente") {
          data.data[i].statusColor = "#ffff00";
        } else if (status == "Evaluado") {
          data.data[i].statusColor = "#70ad47";
        } else if (status == "Rechazado") {
          data.data[i].statusColor = "#d9534f";
        } else if (status == "Pagado") {
          data.data[i].statusColor = "#4472c4";
        }
      }
      console.log("data EVALUATION MISSIONS", data.data);
      return data.data;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getDataToStartEvaluation = async (evaluationId) => {
  try {
    const params = {
      evaluationId: evaluationId,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = await fetch(baseUrl + "/evaluations/start", options);
    data = await data.json();
    //console.log("****************", data);

    if (data.success) {
      //console.log("******************************* 2",data.data);
      return data.data;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getUserSystemId = () => {
  let user_id = localStorage.getItem("ID");
  if (user_id > 0) {
    return user_id;
  } else {
    return 0;
  }
};

const getMultimedias = async (id) => {
  try {
    const params = {
      headers: {},
    };
    let data = await fetch(baseUrl + `/multimedias/evaluation/${id}`, params);
    data = await data.json();
    console.log("getMultimedias", data);

    if (data.success) {
      console.log(data.rows);
      return data.rows;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const validateUser = () => {
  return 1;
};

const createUser = async (userData) => {
  try {
    const params = {
      username: userData.username,
      email: userData.email,
      password: userData.password,
      roleId: userData.roleId,
      statusId: userData.statusId,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        //'Accept': 'application/json',
        "Content-Type": "application/json",
      },
    };
    let data = await fetch(baseUrl + "/user", options);
    data = await data.json();

    if (data.success) {
      console.log("createUser", data);
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getUniqueData = async (url, id) => {
  try {
    let data = await fetch(baseUrl + `/${url}/${id}`);
    data = await data.json();
    if (data.success) {
      if (data.rows && data.rows.length == 0) {
        throw "Datos vacíos";
      }
      return data.rows;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getDataByUrl = async (url) => {
  try {
    let data = await fetch(baseUrl + `/${url}`);
    data = await data.json();
    //console.log("GeneralData", data);
    //console.log("url GeneralData", url);
    if (data.success) {
      //console.log("data.rows NamebyId", data.rows);
      return data.rows;
    } else {
      throw data.message;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export {
  loginData,
  authentication,
  getAuditors,
  updateAuditor,
  deleteAuditor,
  reactiveAuditor,
  validateUser,
  getUsers,
  updateUser,
  getEvaluationMissions,
  evaluationId,
  getDataToStartEvaluation,
  updateEvaluation,
  getMultimedias,
  getRoles,
  updateBalanceAuditor,
  createUser,
  rejectEvaluation,
  getUserSystemId,
  getUniqueData,
  getDataByUrl,
};
